import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Search from "../svg/search-icon.svg"
import Slide from "../components/blog/Slide"
import ArticleSlider from "../components/blog/ArticleSlider"
import ArticlePrev from "../svg/big_prev.svg"
import ArticleNext from "../svg/big_next.svg"
import Article from "../components/blog/Article"
import Left from "../svg/blog-left.svg"
import Right from "../svg/blog-right.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Scrollbar, Pagination, A11y } from "swiper"
import "../css/blog.css"
import Back from "../svg/go_back.svg"
import { Link, graphql } from "gatsby"
import ClientSearch from "../components/ClientSearch"
import "swiper/swiper.scss"

SwiperCore.use([Navigation, Scrollbar, Pagination, A11y])

const BlogIndex = ({ data }) => {
  const items = data.allWpPost.nodes
  const newItems = [...items]
  const [search, setSearch] = useState(false)
  const [selectedSlug, setSelectedSlug] = useState("all")
  let [posts, setPosts] = useState(newItems)

  const postsFilter = (id, slug) => {
    posts = newItems
    const array = posts.filter(
      category => category.categories.nodes[0].id === id
    )
    setPosts([...array])
    setSelectedSlug(slug)
  }

  const categories = data.allWpCategory.edges

  const page = data.allWpPage.edges[0].node.BlogSection

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = []
    
    if(Array.isArray(arr) && arr.length){
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize)
        res.push(chunk)
      }
    }

    return res
  }
  const halfWayIndex = Math.ceil(posts.length / 2)

  const firstHalfOfArray = posts.slice(0, halfWayIndex)
  const secondHalfOfArray = posts.slice(halfWayIndex)
  const posts_chunk = sliceIntoChunks(posts, 6)
  
  const isBrowser = typeof window !== "undefined"
  const paramsSwiper = {
    pagination: {
      renderBullet: (index, className) => {
        return (
          '<span class="text-white flex justify-center items-center  ' +
          className +
          '">' +
          "</span>"
        )
      },
      draggable: true,
      el: ".js-blog-pagination",
      clickable: true,
    },
  }

  const params = {
    pagination: {
      renderBullet: (index, className) => {
        return (
          '<span class="text-white flex justify-center items-center  ' +
          className +
          '">' +
          (index + 1) +
          "</span>"
        )
      },
      draggable: true,
      el: ".js-pagination-pagination",
      clickable: true,
    },
  }

  const searchHandler = () => {
    setSearch(!search)
  }

  if (!posts?.length) {
    return (
      <>
        <Header className="main-container fixed w-full z-50 top-0" />
        <div className="container mx-auto text-center flex flex-col justify-center items-center h-96 my-72">
          <h4 className="text-2xl   text-blue-400   font-medium  text-center ">
            It looks like there are no posts for this category!
          </h4>
          <div className="flex items-center justify-between max-w-xs">
            <Link
              to="/blog"
              onClick={() => {
                if (isBrowser) {
                  window.location.reload()
                }
              }}
              className="bg-green-400 w-8 h-8 flex justify-center items-center rounded-full   hover:bg-green-500 transition "
            >
              <img src={Back} alt="prev-button" className="mb-0" />
            </Link>
            <h5 className="mb-0 ml-5">GO BACK</h5>
          </div>
        </div>
      </>
    )
  }

  return (
    <Layout>
      <SEO title="Blog" />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div id="blog_page" className="main-container  pt-10 lg:pt-24 ">
        <div className="container  px-6">
          <h2 className="text-4xl lg:text-5xl   text-blue-400   font-medium  text-center mb-12 md:mb-20 mt-24">
            {page.blogSectionTitle}
          </h2>
          {search ? (
            <div className="relative mb-10">
              <ClientSearch
                books={items}
                engine={{
                  indexStrategy: "Prefix match",
                  searchSanitizer: "Lower Case",
                  TitleIndex: true,
                  SlugIndex: true,
                  CategoryIndex: true,
                  SearchByTerm: true,
                }}
              />
              <img
                src={Search}
                alt="search-icon"
                className="cursor-pointer absolute right-2 top-2"
                onClick={searchHandler}
              />
            </div>
          ) : (
            <>
              <ul className="list-none ml-0 hidden	 md:flex justify-between items-center mb-10">
                <li
                  onClick={() => {
                    setPosts([...items])
                    setSelectedSlug("all")
                  }}
                  className={`text-xl  font-semibold capitalize  ${
                    selectedSlug === "all"
                      ? "border-b-2 border-blue-400 text-blue-400"
                      : "border-transparent text-gray-400 border-b-2"
                  } transition cursor-pointer`}
                >
                  All
                </li>

                {categories?.map(category => {
                  return (
                    <li
                      key={category.node.id}
                      className={`text-xl font-medium hover:font-semibold capitalize  hover:text-blue-400 ${
                        category.node.slug === selectedSlug
                          ? "border-blue-400 border-b-2 text-blue-400"
                          : "border-transparent text-gray-400 border-b-2"
                      } hover:border-blue-400 transition cursor-pointer`}
                      onClick={() => {
                        postsFilter(category.node.id, category.node.slug)
                      }}
                    >
                      {category.node.name}
                    </li>
                  )
                })}
                <li onClick={searchHandler}>
                  <img
                    src={Search}
                    alt="search-icon"
                    className="cursor-pointer"
                  />
                </li>
              </ul>
              <div className="select-wrapper  block md:hidden">
                <select
                  className="categories_dropwdown cursor-pointer border border-blue-400 w-full	 rounded-full py-2 px-7 focus:outline-none placeholder-gray-500 mb-10"
                  onChange={event => {
                    if (event.target.value === "all") {
                      setPosts([...items])
                      return
                    }
                    postsFilter(event.target.value)
                  }}
                >
                  <option
                    value="all"
                    className="text-xl font-medium hover:font-semibold capitalize  hover:text-blue-400 border-blue-400 border-b-2 text-blue-400 hover:border-blue-400 
                      transition cursor-pointer"
                  >
                    All
                  </option>
                  {categories?.map(category => {
                    return (
                      <option
                        key={category.node.id}
                        value={category.node.id}
                        className="text-xl font-medium hover:font-semibold capitalize  hover:text-blue-400 border-blue-400 border-b-2 text-blue-400 hover:border-blue-400 
                      transition cursor-pointer"
                      >
                        {category.node.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </>
          )}

          <div>
            <Swiper
              {...paramsSwiper}
              spaceBetween={40}
              slidesPerView={1}
              navigation={{
                nextEl: ".js-blog-next",
                prevEl: ".js-blog-prev",
              }}
              keyboard={true}
            >
              {firstHalfOfArray.map(post => (
                <SwiperSlide key={post.uri}>
                  <Slide
                    link={post?.uri}
                    title={post?.title}
                    excerpt={post?.SinglePost?.singlePostExcerpt}
                    post={post?.SinglePost?.singlePostContent}
                    date={post?.date}
                    category={post?.categories?.nodes[0]?.name}
                    imagePost={
                      post?.SinglePost?.singlePostImage?.localFile
                        ?.childImageSharp.fluid
                    }
                    timeToRead={post?.SinglePost?.singlePostTimeToRead}
                    auth={post?.SinglePost?.singelPostAuthor}
                    authPic={
                      post?.SinglePost?.singlePostAuthorPhoto?.localFile
                        ?.childImageSharp.fixed
                    }
                  ></Slide>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="mb-20 relative swiper-cards">
            <Swiper
              spaceBetween={13}
              slidesPerView={1}
              breakpoints={{
                620: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 3,
                },
              }}
              navigation={{
                nextEl: ".js-article-next",
                prevEl: ".js-article-prev",
              }}
              keyboard={true}
              scrollbar={({ draggable: true }, { el: ".js-article-scrollbar" })}
              keyboard={true}
            >
              {secondHalfOfArray.map(post => (
                <SwiperSlide key={post.uri} className="pb-3">
                  <ArticleSlider
                    link={post?.uri}
                    title={post.title}
                    excerpt={post?.SinglePost?.singlePostExcerpt}
                    date={post?.date}
                    category={post?.categories?.nodes[0]?.name}
                    imagePost={
                      post?.SinglePost?.singlePostImage?.localFile
                        ?.childImageSharp.fluid
                    }
                    timeToRead={post?.SinglePost?.singlePostTimeToRead}
                    auth={post?.SinglePost?.singelPostAuthor}
                    authPic={
                      post?.SinglePost?.singlePostAuthorPhoto?.localFile
                        ?.childImageSharp.fixed
                    }
                  ></ArticleSlider>
                </SwiperSlide>
              ))}

              <div className="js-article-scrollbar mt-6 absolute  left-0 overflow-hidden w-full">
                <div
                  className="bg-gray-300 w-full h-px left-0 sticky  "
                  style={{ top: "3.5px" }}
                ></div>
              </div>
            </Swiper>
            <div className="js-article-prev absolute top-1/2  hidden 2xl:block  -left-16 ">
              <img src={ArticlePrev} alt="left" />
            </div>

            <div className="js-article-next absolute top-1/2 hidden 2xl:block -right-16  ">
              <img src={ArticleNext} alt="right" />
            </div>
          </div>
          <div className="mb-16">
            <h2 className="text-4xl lg:text-5xl   text-blue-400   font-medium  text-center mt-8 md:mt-28 mb-9  md:mb-20 ">
              {page.blogSectionSectionTitle}
            </h2>
            <div>
              <Swiper
                {...params}
                spaceBetween={40}
                slidesPerView={1}
                navigation={{
                  nextEl: ".js-pagination-next",
                  prevEl: ".js-pagination-prev",
                }}
                keyboard={true}
              >
                {posts_chunk.map((post, i) => (
                  <SwiperSlide
                    key={i}
                    className="pb-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-14	"
                  >
                    {post.map(post => (
                      <Article
                        key={post?.uri}
                        category={post?.categories?.nodes[0]?.name}
                        title={post?.title}
                        excerpt={post?.SinglePost?.singlePostExcerpt}
                        authPic={
                          post?.SinglePost?.singlePostAuthorPhoto?.localFile
                            ?.childImageSharp.fixed
                        }
                        auth={post?.SinglePost?.singelPostAuthor}
                        date={post?.date}
                        time={post?.SinglePost?.singlePostTimeToRead}
                        link={post?.uri}
                      />
                    ))}
                  </SwiperSlide>
                ))}
                <div className="flex justify-center items-center">
                  <div className="js-pagination-prev  ">
                    <img src={Left} alt="left" className="mb-0 mr-1.5" />
                  </div>
                  <div className="js-pagination-pagination flex items-center"></div>
                  <div className="js-pagination-next  ">
                    <img src={Right} alt="right" className="mb-0 ml-1.5" />
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        id
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        categories {
          nodes {
            name
            id
          }
        }
        SinglePost {
          singelPostAuthor
          singlePostContent
          singlePostExcerpt
          singlePostTimeToRead
          singlePostAuthorPhoto {
            altText
            localFile {
              childImageSharp {
                fixed(width: 40, height: 40) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          singlePostImage {
            altText
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWpCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    allWpPage(filter: { id: { eq: "cG9zdDo0NjI=" } }) {
      edges {
        node {
          title
          BlogSection {
            blogSectionTitle
            blogSectionSectionTitle
          }
        }
      }
    }
  }
`
