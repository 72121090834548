import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ArticleSlider = props => {
  return (
    <Link to={props.link}>
      <div className=" flex flex-col border border-gray-100 rounded-lg h-full">
        <Img
          fluid={props.imagePost}
          // alt={props.imagePost.altText}
          className="rounded-lg h-48"
        />

        <div className=" pt-3.5 pr-4 lg:pr-12 pl-4 pb-6 flex flex-col ">
          <span className="text-blue-500 text-xs font-bold">
            {props.category}
          </span>
          <h3 className="text-base  lg:text-lg text-black font-semibold my-2 leading-7.5 lg:leading-7.5 	">
            {props.title}
          </h3>
          <p className="text-gray-500 text-xs  lg:text-sm">{props.excerpt}</p>
          <div className="flex mt-2.5  ">
            <Img
              fixed={props.authPic}
              // alt={props.authPic.altText}
              className="rounded-full w-10 h-10"
            />
            <div className="flex flex-col   ml-2">
              <p className="mb-0  text-gray-400 text-sm  ">{props.auth}</p>
              <p className="mb-0 text-gray-300 text-sm">
                <span className="mr-2">{props.date}</span>
                {props.timeToRead}
                {props.timeToRead ? <span className="ml-1">read</span> : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticleSlider
