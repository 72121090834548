import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

function Article(props) {
  return (
    <Link to={props.link}>
      <div className="flex flex-col mb-10 md:mb-28 ">
        <div
          className="text-green-800 bg-green-100 text-xs	py-1 px-3.5 font-semibold rounded-full"
          style={{ width: "fit-content" }}
        >
          {props.category}
        </div>
        <h3 className=" text-xl text-black font-semibold  mt-5 mb-3 leading-7.5	">
          {props.title}
        </h3>
        <p
          className="text-gray-500 text-xs font-normal	"
          dangerouslySetInnerHTML={{
            __html: props.excerpt,
          }}
        ></p>
        <div className="flex   ">
          <Img fluid={props.authPic} className="rounded-full w-10 h-10" />
          <div className="flex flex-col   ml-2">
            <p className="mb-0  text-gray-400   text-sm">{props.auth}</p>
            <p className="mb-0 text-gray-300 text-sm">
              <span className="mr-2">{props.date}</span>
              {props.time}{" "}
              {props.time ? <span className="ml-1">read</span> : null}
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Article
