import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Left from "../../svg/blog-left.svg"
import Right from "../../svg/blog-right.svg"

function Slider(props) {
  return (
    <div className="border border-gray-100 rounded-lg mb-12">
      <Link to={props.link}>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Img
            fluid={props.imagePost}
            className="rounded-lg h-60 md:h-96 object-cover"
          />
          <div className="h-80 md:h-96">
            <div className="p-2.5 sm:p-9 lg:p-12 pb-0 sm:pb-0 lg:pb-0 h-full flex flex-col">
              <span className="text-blue-600 text-sm font-bold mt-2">
                {props.category}
              </span>
              <h3 className="overme text-xl md:text-3xl text-black font-semibold my-3 md:my-7 leading-7.5	h-16">
                {props.title}
              </h3>
              <p
                className="text-gray-500 text-xs md:text-base h-16"
                dangerouslySetInnerHTML={{
                  __html: props.excerpt,
                }}
              ></p>
                <div className="flex mt-4">
                  <Img
                    fixed={props.authPic}
                    className="rounded-full w-10 h-10"
                  />
                <div className="flex flex-col   ml-2">
                  <p className="mb-0  text-gray-400 text-sm ">{props.auth}</p>
                  <p className="mb-0 text-gray-300 text-sm font-normal">
                    <span className="mr-2">{props.date}</span>
                    {props.timeToRead}{" "}
                    {props.timeToRead ? <span className="ml-1">read</span> : null}
                  </p>
                </div>
              </div>
            <div className="flex justify-center items-center mt-4">
              <div className="js-blog-prev  ">
                <img src={Left} alt="left" className="mb-1 mr-1.5" />
              </div>
              <div className="js-blog-pagination flex items-center"></div>
              <div className="js-blog-next  ">
                <img src={Right} alt="right" className="mb-1 ml-1.5" />
              </div>
            </div>
             </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Slider
