import React from "react"
import { isEmpty } from "lodash"
import Link from "gatsby-link"

const SearchResults = ({ queryResults }) => {
  if (isEmpty(queryResults)) {
    return null
  }

  return (
    <ul className="absolute ml-0 mb-0 bg-white z-50 border border-blue-400   w-full rounded-3xl">
      {queryResults.map(post => (
        <li
          key={post.id}
          className="text-blue-400 mb-0  cursor-pointer rounded-3xl py-2  pl-3 hover:bg-blue-400 transition hover:text-white"
        >
          <Link to={post.uri}>{post.title}</Link>
        </li>
      ))}
    </ul>
  )
}

export default SearchResults
